import { Typography, Stack, CircularProgress, Alert } from "@mui/material";
import { useMemo } from "react";
import { productAll } from "../../../hooks/useProducts";
import { useAppSelector } from "../../../redux/store";
import { useIsMobile } from "../../../hooks/useResponsive";
import { ClockRecord } from "./ClockRecord";
import { CsvExtractorButton } from "./CsvExtractorButton";

const safeDailyIssuesAll = 25;
const safeDailyIssuesProject = 10;
const safeMinIssuesProduct = 40;
const dangerPeriodDays = 14;

export const ClockContent: React.FC<{}> = () => {
  const isMob = useIsMobile();
  const { report, product, maxIssues, combineIssues } = useAppSelector(
    (s) => s.product
  );
  const { from, to } = useAppSelector((s) => s.range);
  const isSmallAmount = useMemo(() => {
    if (!from || !to || !product) return undefined;
    const days = (to.getTime() - from.getTime()) / 1000 / 60 / 60 / 24;
    if (days > dangerPeriodDays)
      return "Dangerously big period! Results can be incorrect";
    const danger =
      "Dangerously low amount of issues for period! Results can be incorrect";
    if (product.name === productAll) {
      const avgIssues = maxIssues / days;
      if (avgIssues <= safeDailyIssuesAll) return danger;
    } else {
      if (maxIssues >= safeMinIssuesProduct) return undefined;
      const avgIssues = maxIssues / days;
      if (avgIssues <= safeDailyIssuesProject) return danger;
    }
    return undefined;
  }, [from, to, product, maxIssues]);
  const csvAvailable = useMemo(() => {
    return !combineIssues && !report.loading && report.data.length > 0;
  }, [report.data, combineIssues, report.loading]);
  return (
    <>
      {report.error && <Alert severity="error">{report.error}</Alert>}
      {isSmallAmount && <Alert severity="warning">{isSmallAmount}</Alert>}
      {report.loading !== undefined && (
        <Stack
          direction="row"
          spacing={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h3">{report.loading}</Typography>
        </Stack>
      )}
      {csvAvailable && (
        <CsvExtractorButton
          productName={product?.name ?? "-"}
          reportData={report.data}
        />
      )}
      {report.data.map((record) => (
        <ClockRecord
          key={record.author.username}
          record={record}
          isMob={isMob ?? false}
        />
      ))}
    </>
  );
};
