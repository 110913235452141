export const readableTime = (seconds: number) => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const res =
    (days ? `${days}d ` : "") +
    (hours ? `${hours}h ` : "") +
    (minutes ? `${minutes}m ` : "");
  if (res.length === 0) return "0m";
  if (res.at(-1) === " ") return res.slice(0, -1);
  return res;
};
