import { FC } from "react";
import { Issue } from "../../../utils/model";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { readableTime } from "../../../utils/readableTime";
import { styled } from "@mui/material/styles";
import { IssueStateLabel } from "./IssueStateLabel";

interface IssuesTablesProps {
  issues: Issue[];
  pmIssue?: Issue;
}

const PaperGray = styled(Paper)(() => ({
  backgroundColor: "#f4f2f5",
}));

export const IssuesTables: FC<IssuesTablesProps> = ({ pmIssue, issues }) => {
  if (issues.length === 0) return <></>;
  return (
    <TableContainer component={PaperGray}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Dev issue</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>State</TableCell>
            <TableCell align="right">Milestone</TableCell>
            <TableCell align="right">Spend</TableCell>
            <TableCell align="right">Update Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {issues.map((row) => (
            <TableRow
              key={`${row.iid}-${row.project.id}-${row.project.name}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <a href={row.webUrl} target="_blank" rel="noopener noreferrer">
                  {row.title}
                </a>
              </TableCell>
              <TableCell align="left">
                <a
                  href={row.project.webUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.project.name}
                </a>
              </TableCell>
              <TableCell align="right">
                <IssueStateLabel issue={row} />
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: pmIssue
                    ? row.milestone?.title === pmIssue.milestone?.title
                      ? "black"
                      : "red"
                    : "black",
                }}
              >
                {row.milestone?.title ?? "No Sprint"}
              </TableCell>
              <TableCell align="right">
                {readableTime(row.time_stats?.total_time_spent ?? 0)}
              </TableCell>
              <TableCell align="right">
                {row.updatedAt.toLocaleDateString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
