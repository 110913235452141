import { FC } from "react";
import { ReportRecord } from "../../../hooks/useClockReport";
import { Button } from "@mui/material";
import { prepareClockCsvData } from "./prepareClockCsvData";

interface CsvExtractorButtonProps {
  reportData: ReportRecord[];
  productName: string;
}

export const CsvExtractorButton: FC<CsvExtractorButtonProps> = ({
  reportData,
  productName,
}) => {
  const handleDownloadCsv = async () => {
    try {
      // Call the external function to prepare CSV data
      const csvData = await prepareClockCsvData(reportData);

      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${productName}-${new Date().toLocaleDateString()}.csv`; // Set the file name
      document.body.appendChild(a);
      a.click(); // Trigger the download

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error generating or downloading CSV:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{ mt: 2 }}
      onClick={handleDownloadCsv}
    >
      Download CSV
    </Button>
  );
};
