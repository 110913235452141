import { ClockComment, Issue } from "../utils/model";
import { gitlabClient } from "./client";

const getClockRecordsPaged = async (
  issue: Issue,
  page: number
): Promise<{ clocks: ClockComment[]; total: number }> => {
  const res = await gitlabClient.get(
    `projects/${issue.project.id}/issues/${issue.iid}/discussions`,
    {
      params: {
        per_page: process.env.REACT_APP_DISCUSSION_MAX_LOAD,
        page,
      },
    }
  );
  const data: [
    {
      notes: [
        {
          author: {
            name: string;
            username: string;
            avatar_url: string;
            web_url: string;
          };
          body: string;
          id: number;
          system: boolean;
          updated_at: string;
          created_at: string;
        }
      ];
    }
  ] = res.data;
  const notes = data.flatMap((resp) => resp.notes);
  const total = notes.length;
  const clocks = notes
    .filter((r) => r.system)
    .map<ClockComment>(({ body, author, ...resp }) => {
      const dP = /\d+(?=d )/g;
      const mP = /\d+(?=m )/g;
      const hP = /\d+(?=h )/g;
      const wP = /\d+(?=w )/g;

      const dR = body.match(dP);
      const mR = body.match(mP);
      const hR = body.match(hP);
      const wR = body.match(wP);

      const d = dR ? Number(dR[0]) : 0;
      const m = mR ? Number(mR[0]) : 0;
      const h = hR ? Number(hR[0]) : 0;
      const w = wR ? Number(wR[0]) : 0;

      let spend = (w * 5 + d) * 8 + h + m / 60;

      if (body.indexOf("estimate") >= 0) {
        spend = 0;
      } else if (body.indexOf("added") >= 0) {
        // all ok
      } else if (body.indexOf("deleted") >= 0) {
        // deleted
        spend = 0 - spend;
      } else if (body.indexOf("subtracted") >= 0) {
        // subtracted
        spend = 0 - spend;
      } else if (spend > 0) {
        console.error("unknown timetracking comment", body);
        spend = 0;
      }
      const closest = notes
        .filter((n) => !n.system && n.author.username === author.username)
        .find(
          (n) =>
            n.id !== resp.id &&
            Math.abs(
              new Date(n.created_at).getTime() -
                new Date(resp.created_at).getTime()
            ) < 2000
        );

      return {
        updatedAt: new Date(resp.updated_at),
        id: resp.id,
        link: `${issue.webUrl}#note_${closest ? closest.id : resp.id}`,
        spend,
        author: {
          avatar: author.avatar_url,
          name: author.name,
          url: author.web_url,
          username: author.username,
        },
        issue,
      };
    })
    .filter((r) => r.spend !== 0);
  return { clocks, total };
};

export const getClockRecords = async (
  issue: Issue
): Promise<ClockComment[]> => {
  const res: ClockComment[] = [];
  let curTotal = 0;
  let page = 1;
  do {
    const { clocks, total } = await getClockRecordsPaged(issue, page);
    curTotal = total;
    res.push(...clocks);
    page++;
  } while (curTotal !== 0);
  return res;
};
