import { FC, PropsWithChildren, useState } from "react";
import { useAppSelector } from "../../../redux/store";
import {
  Alert,
  Button,
  ButtonGroup,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { EstimateEpicInfo } from "./EstimateEpictInfo";
import { IssuesTables } from "./IssuesTables";

const Wrapper: React.FC<{ color: string } & PropsWithChildren> = ({
  children,
  color,
}) => {
  return (
    <Paper
      sx={{
        padding: 2,
        margin: 2,
        background: color,
      }}
    >
      {children}
    </Paper>
  );
};

export const EstimateReport: FC = () => {
  const { error, loading, report } = useAppSelector((s) => s.estimate);
  const [skipZeroTasks, setSkipZeroTasks] = useState(false);
  const [issuesState, setIssuesState] = useState<"All" | "Closed" | "Open">(
    "All"
  );
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h5">Skip zero tasks</Typography>
        <Switch
          checked={skipZeroTasks}
          onChange={() => setSkipZeroTasks(!skipZeroTasks)}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Typography variant="h5">PM Issues:</Typography>
        <ButtonGroup
          color="secondary"
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={() => setIssuesState("All")}
            variant={issuesState === "All" ? "contained" : "outlined"}
          >
            All
          </Button>
          <Button
            onClick={() => setIssuesState("Open")}
            variant={issuesState === "Open" ? "contained" : "outlined"}
          >
            Opened
          </Button>
          <Button
            onClick={() => setIssuesState("Closed")}
            variant={issuesState === "Closed" ? "contained" : "outlined"}
          >
            Closed
          </Button>
        </ButtonGroup>
      </Stack>
      {error && <Alert severity="error">{error}</Alert>}
      {loading && <Alert severity="info">{loading}</Alert>}
      {report && (
        <div>
          {Object.entries(report.linkedMap)
            .sort((a, b) => (a[0] > b[0] ? 1 : -1))
            .map(([sprintName, issues], i) => (
              <Wrapper
                key={sprintName}
                color={
                  sprintName === ""
                    ? "#ffbbbb"
                    : i % 2 === 0
                    ? "#eefefe"
                    : "#feeeff"
                }
              >
                <Typography variant="h5" gutterBottom>
                  {sprintName === "" ? "No sprint name" : sprintName}
                </Typography>
                {issues.map((sprint) => (
                  <EstimateEpicInfo
                    key={sprint.pmIssue.iid}
                    sprintName={sprintName}
                    skipZeroTasks={skipZeroTasks}
                    sprint={sprint}
                    showIssues={issuesState}
                  />
                ))}
              </Wrapper>
            ))}
          <Wrapper color="#ffdddd">
            <Typography variant="h5">Unlinked dev issues</Typography>
            <IssuesTables
              issues={report.devIssues.filter((i) => {
                if (issuesState === "All") return true;
                if (issuesState === "Closed") return i.state === "closed";
                return i.state !== "closed";
              })}
            />
          </Wrapper>
        </div>
      )}
    </>
  );
};
