import { Button, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useIsBigScreen, useIsMobile } from "../../../hooks/useResponsive";
import { setFromRange, setToRange } from "../../../redux/rangeSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { PropsWithChildren } from "react";
import { RecordFunc } from "../../../utils/model";

export const ClockWrap: React.FC<
  PropsWithChildren & { generateReport: RecordFunc }
> = ({ children, generateReport }) => {
  const { maxIssues, product, user } = useAppSelector((s) => ({
    maxIssues: s.product.maxIssues,
    product: s.product.product,
    user: s.user.user,
  }));

  const dispatch = useAppDispatch();
  const { from, to } = useAppSelector((s) => ({
    from: s.range.from ? new Date(s.range.from) : undefined,
    to: s.range.to ? new Date(s.range.to) : undefined,
  }));
  const isBig = useIsBigScreen();
  const isMob = useIsMobile();

  const DP: React.FC<{ d?: Date; setD: any; l: string }> = ({ d, setD, l }) => (
    <DatePicker
      label={l}
      value={d}
      onChange={(newValue) => {
        if (!newValue) return;
        dispatch(setD(newValue));
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );

  if (!user) return <Typography>Please provide your access key</Typography>;
  if (!product) return <Typography>Please select product</Typography>;

  return (
    <>
      <Stack
        direction="row"
        spacing={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        {isBig && <Typography>Product: {product.name}</Typography>}
        <DP d={from} l="From" setD={setFromRange} />
        <DP d={to} l="To" setD={setToRange} />

        {from && to && !isMob && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              generateReport({
                from: from as Date,
                product,
                to: to as Date,
                maxIssues,
              })
            }
          >
            Generate
          </Button>
        )}
      </Stack>
      {from && to && isMob && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            generateReport({
              from: from as Date,
              product,
              to: to as Date,
              maxIssues,
            })
          }
        >
          Generate
        </Button>
      )}
      {children}
    </>
  );
};
