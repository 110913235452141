import { Paper } from "@mui/material";
import { FC, useMemo } from "react";
import { getIssueStateText } from "../../../utils/getIssueStateText";
import { Issue } from "../../../utils/model";

const labelsColors: Record<string, string> = {
  "To Do": "#f0ad4e",
  Doing: "#5cb85c",
  "In Review": "#428bca",
  "To Develop": "#cd5b45",
  "In Dev": "#009966",
  Ready: "#c21e56",
};

export const IssueStateLabel: FC<{ issue: Issue }> = ({ issue }) => {
  const closed = issue.state === "closed";
  const labels = issue.labels;
  const color = useMemo(() => {
    if (closed) return "#e2f2ce";
    if (labels.length === 0) return "#9c27b0";
    return labelsColors[labels[0]] ?? "#9c27b0";
  }, [closed, labels]);
  return (
    <Paper
      sx={{
        background: color,
        padding: 1,
        color: closed ? "black" : "#eeeeee",
        marginBottom: 2,
      }}
    >
      {getIssueStateText(issue)}
    </Paper>
  );
};
