import {
  Typography,
  Stack,
  Paper,
  Avatar,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useMemo } from "react";
import { ReportRecord } from "../../../hooks/useClockReport";
import { useAppSelector } from "../../../redux/store";
import { ClockComment } from "../../../utils/model";

export const ClockRecord: React.FC<{
  record: ReportRecord;
  isMob: boolean;
}> = ({ record, isMob }) => {
  const combineIssues = useAppSelector((s) => s.product.combineIssues);
  const { records, hasMileStone } = useMemo(() => {
    const hasMileStone = (combined: ClockComment[]) =>
      combined.findIndex((r) => !!r.issue.milestone?.title) >= 0;
    let res = [...record.records].sort(
      (a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()
    );
    if (combineIssues) {
      const combined: ClockComment[] = [];
      for (const record of res) {
        const mainRecord = combined.find(
          (c) =>
            c.issue.iid === record.issue.iid &&
            c.issue.project.id === record.issue.project.id
        );
        if (mainRecord) {
          mainRecord.id = mainRecord.issue.iid;
          mainRecord.link = mainRecord.issue.webUrl;
          mainRecord.spend += record.spend;
        } else
          combined.push({
            author: record.author,
            id: record.id,
            issue: record.issue,
            link: record.link,
            spend: record.spend,
            updatedAt: record.updatedAt,
          });
      }
      return { records: combined, hasMileStone: hasMileStone(combined) };
    }
    return { records: res, hasMileStone: hasMileStone(res) };
  }, [record.records, combineIssues]);
  return (
    <Paper sx={{ mt: 2 }}>
      <Stack
        direction="column"
        spacing={5}
        sx={{ alignItems: "center", padding: 2 }}
      >
        <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={1}>
          <Avatar
            component={"a"}
            href={record.author.url}
            target="_blank"
            rel="noopener noreferrer"
            src={record.author.avatar}
          />
          {!isMob && (
            <Typography variant="h6" color={"#9c27b0"}>
              {record.author.name}'s
            </Typography>
          )}
          <Typography variant="h6">Total:</Typography>
          <Typography variant="h6" color={"#9c27b0"}>
            {+record.total.toFixed(2)}
          </Typography>
          <Typography variant="h6"> hours</Typography>
          <Button
            color="secondary"
            onClick={() => navigator.clipboard.writeText(String(record.total))}
          >
            Copy
          </Button>
        </Stack>
        <Typography variant="h6">Work Log:</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Issue</TableCell>
                <TableCell>Project</TableCell>
                <TableCell align="right">Spend</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Time</TableCell>
                {hasMileStone && <TableCell align="right">Milestone</TableCell>}
                {combineIssues && <TableCell align="right">Estimate</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((row) => (
                <TableRow
                  key={`${row.issue.iid}-${row.id}-${row.issue.project.id}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <a
                      href={row.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.issue.title}
                    </a>
                  </TableCell>
                  <TableCell align="left">
                    <a
                      href={row.issue.project.webUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.issue.project.name}
                    </a>
                  </TableCell>
                  <TableCell align="right">{+row.spend.toFixed(2)}</TableCell>
                  <TableCell align="right">
                    {row.updatedAt.toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.updatedAt.toLocaleTimeString()}
                  </TableCell>
                  {hasMileStone && (
                    <TableCell align="right">
                      {row.issue.milestone?.title ?? ""}
                    </TableCell>
                  )}
                  {combineIssues && (
                    <TableCell align="right">
                      {row.issue.time_stats?.time_estimate
                        ? row.issue.time_stats.time_estimate / 60 / 60 + " h"
                        : "-"}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Paper>
  );
};
