import { Project } from "../utils/model";
import { gitlabClient } from "./client";

export const getProjectInfo = async (
  projectId: number
): Promise<Project | undefined> => {
  try {
    if (!localStorage.getItem("token")) return undefined;
    const res = await gitlabClient.get(`projects/${projectId}`);
    const data: { web_url: string; name: string } = res.data;
    return {
      id: projectId,
      name: data.name,
      webUrl: data.web_url,
    };
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return undefined;
    }
    throw e;
  }
};
