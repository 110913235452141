import { Issue, Project } from "../utils/model";
import { gitlabClient } from "./client";

export interface GitlabIssue {
  web_url: string;
  iid: number;
  title: string;
  updated_at: string;
  project_id: number;
  state: string;
  milestone: {
    title: string;
  } | null;
  labels: string[];
  time_stats?: {
    time_estimate?: number;
    total_time_spent?: number;
  };
}

// https://gitlab.maugry.ru/api/v4/projects/180/issues/49/links

const getIssues = async (
  link: string,
  maxIssues: number
): Promise<GitlabIssue[]> => {
  const res = await gitlabClient.get(link, {
    params: {
      per_page: maxIssues,
      order_by: "updated_at",
    },
  });
  const data: [GitlabIssue] = res.data;
  return data;
};

const mapGitlabIssue =
  (project: Project, projects?: Project[]) =>
  (issue: GitlabIssue): Issue => ({
    webUrl: issue.web_url,
    iid: issue.iid,
    title: issue.title,
    updatedAt: new Date(issue.updated_at),
    milestone: issue.milestone,
    time_stats: issue.time_stats,
    labels: issue.labels,
    state: issue.state,
    project: projects?.find((p) => p.id === issue.project_id) ?? project,
  });

export const getProjectIssues = async (
  project: Project,
  maxIssues: number,
  projects?: Project[],
  getLinks?: boolean
): Promise<Issue[]> => {
  try {
    const data = await getIssues(`projects/${project.id}/issues`, maxIssues);
    const issues: Issue[] = data.map(mapGitlabIssue(project, projects));
    if (getLinks) {
      const mappedPromises = issues.map(async (issue) => {
        const links = await getIssues(
          `projects/${project.id}/issues/${issue.iid}/links`,
          maxIssues
        );
        return {
          ...issue,
          links: links.map(mapGitlabIssue(project, projects)),
        } as Issue;
      });
      return Promise.all(mappedPromises);
    }

    return issues;
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return [];
    }
    throw e;
  }
};
