export const whiteListUsers = [
  "qpIlIpp",
  "kyurkov",
  "adutova99",
  "Alexey",
  "adurakov",
  "savinova.v",
  "kotlov.m",
  "ponomareva.k",
  "mzamanov",
].map((s) => s.toLowerCase());
