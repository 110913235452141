import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Issue } from "../utils/model";

export interface EstimateRecordIssue {
  pmIssue: Issue;
  devIssues: Issue[];
  estimate: number;
  actual: number;
}

export interface EstimateReportData {
  linkedMap: Record<string, EstimateRecordIssue[]>;
  devIssues: Issue[];
}

export interface EstimateReportState {
  report?: EstimateReportData;
  loading?: string;
  error?: string;
}

const initialState: EstimateReportState = {};

export const estimateReportSlice = createSlice({
  name: "estimate",
  initialState,
  reducers: {
    setEstimateReport: (state, action: PayloadAction<EstimateReportData>) => {
      state.report = action.payload;
    },
    clearEstimateReport: (state) => {
      state.report = undefined;
      state.loading = undefined;
      state.error = undefined;
    },
    setEstimateReportError: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.error = action.payload;
    },
    setEstimateReportLoading: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEstimateReport,
  clearEstimateReport,
  setEstimateReportError,
  setEstimateReportLoading,
} = estimateReportSlice.actions;

export const estimateReportReducer = estimateReportSlice.reducer;
