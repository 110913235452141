import { stringify } from "csv-stringify/browser/esm/sync";
import { ReportRecord } from "../../../hooks/useClockReport";

export const prepareClockCsvData = (recordData: ReportRecord[]) => {
  const dataToCsv = recordData
    .map((record) => {
      return record.records.map((timeRecord) => {
        return [
          timeRecord.author.username,
          timeRecord.author.name,
          timeRecord.issue.project.id,
          timeRecord.issue.project.name,
          timeRecord.issue.iid,
          timeRecord.issue.title,
          timeRecord.spend.toFixed(2),
          timeRecord.updatedAt.toLocaleDateString(),
          timeRecord.updatedAt.toLocaleTimeString(),
          timeRecord.issue.milestone?.title ?? "",
        ];
      });
    })
    .flat();
  dataToCsv.unshift([
    "AuthorUsername",
    "AuthorName",
    "ProjectID",
    "Project",
    "IssueID",
    "Issue",
    "Spend",
    "Date",
    "Time",
    "Milestone",
  ]);
  return stringify(dataToCsv);
};
