import { FC } from "react";
import { EstimateRecordIssue } from "../../../redux/estimateReportSlice";
import { IssuesTables } from "./IssuesTables";
import { Link, Paper, Typography } from "@mui/material";
import { readableTime } from "../../../utils/readableTime";
import { IssueStateLabel } from "./IssueStateLabel";

interface EstimateEpicInfoProps {
  sprintName: string;
  sprint: EstimateRecordIssue;
  skipZeroTasks: boolean;
  showIssues: "All" | "Closed" | "Open";
}

const EstimateStatus: FC<{ estimate: number; actual: number }> = ({
  estimate,
  actual,
}) => {
  let color = "#9c27b0";
  if (estimate > actual) color = "#5cff59";
  if (estimate < actual) color = "#f44336";
  if (estimate === actual) color = "#4caf50";
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        padding: 1,
        marginBottom: 2,
        background: color,
      }}
    >
      <Typography variant="h6">
        A/E: {readableTime(actual)} / {readableTime(estimate)}{" "}
      </Typography>
    </Paper>
  );
};

export const EstimateEpicInfo: FC<EstimateEpicInfoProps> = ({
  skipZeroTasks,
  sprintName,
  sprint,
  showIssues,
}) => {
  if (skipZeroTasks && sprint.estimate === 0 && sprint.actual === 0)
    return <></>;
  if (showIssues === "Closed" && sprint.pmIssue.state !== "closed")
    return <></>;
  if (showIssues === "Open" && sprint.pmIssue.state === "closed") return <></>;
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        padding: 3,
        marginBottom: 2,
      }}
    >
      <Typography variant="h6">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={sprint.pmIssue.webUrl}
        >
          {sprint.pmIssue.title}
        </Link>
      </Typography>
      <EstimateStatus actual={sprint.actual} estimate={sprint.estimate} />
      <IssueStateLabel issue={sprint.pmIssue} />
      {(sprint.pmIssue.time_stats?.total_time_spent ?? 0) > 0 && (
        <Typography variant="h6">
          PM Actual:{" "}
          {readableTime(sprint.pmIssue.time_stats?.total_time_spent ?? 0)}
        </Typography>
      )}
      <IssuesTables issues={sprint.devIssues} pmIssue={sprint.pmIssue} />
    </Paper>
  );
};
