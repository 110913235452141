import { useCallback } from "react";
import {
  clearEstimateReport,
  EstimateReportData,
  setEstimateReport,
  setEstimateReportError,
  setEstimateReportLoading,
} from "../redux/estimateReportSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { Issue, RecordFunc, ReportParams } from "../utils/model";
import { whiteListUsers } from "../utils/whiteListUsers";
import { getProjectIssues } from "../gitlabApi/getProjectIssues";

export const useEstimateReportGenerate = (): RecordFunc => {
  const username = useAppSelector((s) => s.user.user?.username?.toLowerCase());
  const wl = whiteListUsers.indexOf(username ?? "") >= 0;
  const dispatch = useAppDispatch();
  const action = useCallback(
    async ({ from, to, product, maxIssues }: ReportParams) => {
      const dateFilter = (date: Date) => {
        return (
          date.getTime() >= from.getTime() && date.getTime() <= to.getTime()
        );
      };
      const issueIsActual = (issue: Issue) =>
        dateFilter(issue.updatedAt) || dateFilter(issue.updatedAt);

      dispatch(clearEstimateReport());
      if (!wl) {
        dispatch(setEstimateReportError("No access. Ask Philipp"));
        return;
      }
      if (!product.pmProjectId) {
        dispatch(
          setEstimateReportError("This project doesn't have pm project")
        );
        return;
      }

      try {
        dispatch(setEstimateReportLoading("Loading..."));

        let allPmIssues: Issue[] = [];
        let allDevIssues: Issue[] = [];
        const pmIssuesMap: Record<string, { pm: Issue; dev: Issue[] }> = {};

        const safeAddIssues = (arr: Issue[], issues: Issue[]) => {
          for (const issue of issues) {
            if (!arr.find((i) => i.iid === issue.iid)) {
              arr.push(issue);
            }
          }
        };
        const safeAddIssuesWithLinks = (
          targetArr: Issue[],
          linkedArr: Issue[],
          issues: Issue[]
        ) => {
          safeAddIssues(targetArr, issues);
          for (const issue of issues) {
            safeAddIssues(linkedArr, issue.links ?? []);
          }
        };

        const EstimateReportData: EstimateReportData = {
          linkedMap: {},
          devIssues: [],
        };
        for (const project of product.projects) {
          dispatch(setEstimateReportLoading(project.name));
          const issues = await getProjectIssues(
            project,
            maxIssues,
            product.projects,
            true
          );
          if (product.pmProjectId === project.id) {
            safeAddIssuesWithLinks(allPmIssues, allDevIssues, issues);
          } else {
            safeAddIssuesWithLinks(allDevIssues, allPmIssues, issues);
          }
        }
        dispatch(setEstimateReportLoading("Processing issues"));

        for (const issue of allPmIssues) {
          pmIssuesMap[issue.iid] = { pm: issue, dev: [] };
        }

        for (const issue of allDevIssues) {
          // remove spies
          if (issue.project.id === product.pmProjectId) continue;

          const pmIssueId = issue.links?.find(
            (l) => l.project.id === product.pmProjectId
          )?.iid;
          if (pmIssueId) {
            pmIssuesMap[pmIssueId].dev.push(issue);
          } else {
            if (issueIsActual(issue)) EstimateReportData.devIssues.push(issue);
          }
        }

        for (const key in pmIssuesMap) {
          if (Object.prototype.hasOwnProperty.call(pmIssuesMap, key)) {
            const pmIssue = pmIssuesMap[key];
            if (
              !issueIsActual(pmIssue.pm) &&
              pmIssue.dev.filter(issueIsActual).length === 0
            ) {
              continue;
            }
            const milestone = pmIssue.pm.milestone?.title ?? ""; // Empty string can be Record key
            if (!EstimateReportData.linkedMap[milestone]) {
              EstimateReportData.linkedMap[milestone] = [];
            }
            const actual = pmIssue.dev.reduce(
              (acc, devIssue) =>
                acc + (devIssue.time_stats?.total_time_spent ?? 0),
              0
            );
            EstimateReportData.linkedMap[milestone].push({
              pmIssue: pmIssue.pm,
              devIssues: pmIssue.dev,
              estimate: pmIssue.pm.time_stats?.time_estimate ?? 0,
              actual,
            });
          }
        }
        dispatch(setEstimateReport(EstimateReportData));
      } catch (err: any) {
        dispatch(setEstimateReportError(err.message));
      } finally {
        dispatch(setEstimateReportLoading(undefined));
      }
    },
    [dispatch, wl]
  );
  return action;
};
