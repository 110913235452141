import { Issue, Product } from "../utils/model";
import { gitlabClient } from "./client";
import { GitlabIssue } from "./getProjectIssues";

const processIssues = (data: GitlabIssue[], products: Product[]) => {
  const projectedIssues = data.map<Omit<Issue, "project"> & { project: any }>(
    (d) => ({
      webUrl: d.web_url,
      iid: d.iid,
      title: d.title,
      updatedAt: new Date(d.updated_at),
      milestone: d.milestone,
      time_stats: d.time_stats,
      labels: d.labels,
      state: d.state,
      project: products
        .flatMap((p) => p.projects)
        .find((p) => p.id === d.project_id),
    })
  );
  return projectedIssues.filter((p) => !!p.project) as Issue[];
};

export const getIssues = async (
  products: Product[],
  maxIssues: number
): Promise<Issue[]> => {
  try {
    let loop;
    let page = 1;
    let fetched = 0;
    const issues: Issue[] = [];
    do {
      const res = await gitlabClient.get(`issues`, {
        params: {
          per_page: maxIssues,
          order_by: "updated_at",
          scope: "all",
          page,
        },
      });
      page++;
      const data: GitlabIssue[] = res.data;
      fetched += data.length;
      issues.push(...processIssues(data, products));
      loop = fetched < maxIssues;
      if (data.length === 0) break;
    } while (loop);
    return issues;
  } catch (e: any) {
    if (e?.response?.status === 404) {
      // not enough permissions
      console.error(e);
      return [];
    }
    throw e;
  }
};
