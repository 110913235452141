import {
  Alert,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { Radio, RadioGroup } from "@mui/material";
import { productAll, useProducts } from "../../hooks/useProducts";
import {
  setCombineIssues,
  setMaxIssues,
  setProduct,
} from "../../redux/productSlice";
import { setFromRange, setToRange } from "../../redux/rangeSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Product } from "../../utils/model";

export const ProjectSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const { products, loading, error } = useProducts();
  const { product, combineIssues, maxIssues } = useAppSelector(
    (s) => s.product
  );
  const timeChanged = useAppSelector((s) => s.range.changed);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={combineIssues}
            onChange={(e) => dispatch(setCombineIssues(e.target.checked))}
            color="secondary"
          />
        }
        label="Group By Issues"
      />
      <TextField
        fullWidth
        value={maxIssues}
        label="Max Issues Load"
        onChange={(e) => dispatch(setMaxIssues(e.target.value))}
      />
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}

      <RadioGroup
        onChange={(e) => {
          const product: Product = JSON.parse(e.target.value);
          dispatch(setProduct(product));
          if (!timeChanged && product.name === productAll) {
            dispatch(setFromRange(new Date()));
            dispatch(setToRange(new Date()));
          }
        }}
      >
        {products.map((p) => (
          <FormControlLabel
            checked={p.name === product?.name}
            key={p.name}
            value={JSON.stringify(p)}
            control={
              <Radio color={p.name === productAll ? "warning" : "primary"} />
            }
            label={p.name}
          />
        ))}
      </RadioGroup>
    </>
  );
};
