import { Box, AppBar, Grid, Paper, Stack, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserSelector } from "./UserSelector";
import { ClockWrap } from "./ClockReport/ClockWrap";
import { useIsBigScreen } from "../../hooks/useResponsive";
import React from "react";
import { useClockReportGenerate } from "../../hooks/useClockReport";
import { ClockContent } from "./ClockReport/ClockContent";
import { useEstimateReportGenerate } from "../../hooks/useEstimateReport";
import { EstimateReport } from "./EstimateReport/EstimateReport";

const ItemBig = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f9f9f9",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "calc(100vh - 101px)",
  height: "calc(100% - 53px)",
  overflow: "hidden",
}));

const ItemSmall = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f9f9f9",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  overflow: "hidden",
}));

const Bar = React.memo(() => {
  return (
    <AppBar position="static" color="secondary">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        Gitlab Time Tracker
      </Box>
    </AppBar>
  );
});

const Selector = React.memo(() => <UserSelector />);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const TrackerComp: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const generateClockReport = useClockReportGenerate();
  const generateEstimateReport = useEstimateReportGenerate();
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Clock Report" {...a11yProps(0)} />
          <Tab label="Estimations analyze" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ClockWrap generateReport={generateClockReport}>
          <ClockContent />
        </ClockWrap>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ClockWrap generateReport={generateEstimateReport}>
          <EstimateReport />
        </ClockWrap>
      </CustomTabPanel>
    </>
  );
};

const Tracker = React.memo(() => <TrackerComp />);

export const MainPage: React.FC = () => {
  const isBig = useIsBigScreen();
  return (
    <>
      {isBig && (
        <>
          <Bar />
          <Grid container spacing={0} sx={{ background: "#9c27b022" }}>
            <Grid item xs={3}>
              <ItemBig>
                <Selector />
              </ItemBig>
            </Grid>
            <Grid item xs={9}>
              <ItemBig>
                <Stack spacing={2}>
                  <Tracker />
                </Stack>
              </ItemBig>
            </Grid>
          </Grid>
        </>
      )}
      {!isBig && (
        <>
          <Bar />
          <Stack direction="column">
            <ItemSmall>
              <Selector />
            </ItemSmall>
            <ItemSmall>
              <Tracker />
            </ItemSmall>
          </Stack>
        </>
      )}
    </>
  );
};
