import { UserInfo } from "../utils/model";
import { gitlabClient } from "./client";

export const getUserInfo = async (token: string): Promise<UserInfo> => {
  const res = await gitlabClient.get("user", {
    headers: { "PRIVATE-TOKEN": token },
  });
  const data = res.data;
  const url: string = data.web_url;
  const avatar: string = data.avatar_url;
  const name: string = data.name;
  const username: string = data.username;
  return { avatar, url, name, username };
};
