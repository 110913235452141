import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./userSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { productReducer } from "./productSlice";
import { rangeReducer } from "./rangeSlice";
import { estimateReportReducer } from "./estimateReportSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    product: productReducer,
    range: rangeReducer,
    estimate: estimateReportReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "product/setReportData",
          "range/setToRange",
          "range/setFromRange",
        ],
        // Ignore these field paths in all actions
        //ignoredActionPaths: ["range.from"],
        // Ignore these paths in the state
        ignoredPaths: ["product.report", "range.from", "range.to"],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
